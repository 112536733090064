import React from 'react'
import { graphql } from 'gatsby';
import { Translation } from 'react-i18next';
import { withI18next, Link } from '@wapps/gatsby-plugin-i18next';
import Layout from 'layouts/index'

class SitemapPage extends React.Component {
    state = {
        pages: [
            {title: 'seatFactoryTours', path: "", trans: true},
            {title: 'requestTransact', path: "/request", trans: true},
            {title: 'cancelTitle', path: "/cancel", trans: true}
        ]
    }

    render(){
        const {pages} = this.state;
        return (<Translation>{t => (
            <Layout name={'sitemapPage'} title={t('sitemap')} {...this.props}>
                <div className="content-block container-fuild">
                    <div className="row">
                        <div className="content-block-content col-xs-12 col-sm-8 col-md-6">
                            <h1>{t('sitemap')}</h1>
                            <ul className="sitemap">
                                { pages.map( (page,k) => <li key={k}><Link to={page.path}>{page.trans ? t(page.title) : page.title}</Link></li>)}
                            </ul>
                        </div>
                    </div>
                </div>
            </Layout>
        )}</Translation>);
    }
}

export const query = graphql`
  query($lng: String!) {
      allGroupsList(filter: { _lang: { eq: $lng } },sort:{fields: [_id], order: ASC}) {
        edges {
          node {
            title
            slug
            buildings{
                slug
                title
            }
          }
        }
      }
    locales: allLocale(filter: { lng: { eq:$lng }, ns: { eq: "messages" } }) {
      ...LocaleFragment
    }
    site {
      siteMetadata {
        siteTitle
        activeEnv
        analytics
      }
    }
  }
`;

export default withI18next()(SitemapPage);
